import React from 'react';
import PropTypes from 'prop-types';
import withDataHOC from '../dataProvider/withDataHOC';
import Select from '../general/Select';
import {GLOBAL_DATA} from '../../constants/globalData';
import { createFetchFuelTypes } from '../../backend/apiCalls';

/**
 * @dusan
 */

class FuelTypeSelect extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
        value: PropTypes.number,
        onChange: PropTypes.func.isRequired,
        allowClear: PropTypes.bool,
    };

    static defaultProps = {
        allowClear: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            types: [],
            loading: false,
        };
    }

    fetchTaxRates = () => {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
        this.setState({loading: true});
        fetchHandler(
            createFetchFuelTypes(),
            {},
            (result) => {
                this.setState({
                    types: result,
                    loading: false,
                });
            }
        );
    };

    componentDidMount() {
        this.fetchTaxRates();
    }

    render() {
        const {[GLOBAL_DATA.FETCH_HANDLER]: fetchHandler, disabled, ...props} = this.props;
        const {types, loading} = this.state;
        return <Select
            className="full-size-width"
            {...props}
            disabled={loading || disabled}
            options={types.map(type => {
                const typeChecked = type != null ? type : {};
                return {
                    label: typeChecked.name,
                    value: typeChecked.id,
                }
            })}
            isSearchable={false}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(FuelTypeSelect);