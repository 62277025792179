import React from 'react';
import PropTypes from 'prop-types';
import visible from '../../svg/visible.svg';
import hidden from '../../svg/hidden.svg';
import Tooltip from './Tooltip';
import {Trans, t} from '@lingui/macro';

/**
 * @fero
 */

class VisibilityIcon extends React.PureComponent {
    static propTypes = {
        isVisible: PropTypes.number,
        tooltip: PropTypes.node,
    };

    render() {
        const {isVisible, tooltip} = this.props;
        if(isVisible == null || isVisible == 1)
        	return null;
        else
        	return <Tooltip title={tooltip}>
        		<img className="visibility-icon mr-1" src={hidden} alt="hidden"/>
        	</Tooltip>;
    }
}

export default VisibilityIcon;