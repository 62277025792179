import React from 'react';
import PropTypes from 'prop-types';
import generalFailedPC from '../../fetch/generalFailedPC';
import Tooltip from '../../general/Tooltip';
import FormModal from '../../fetch/FormModal';
import generalNoParameterFormPC from '../../fetch/generalNoParameterFormPC';
import withDataHOC from '../../dataProvider/withDataHOC';
import {Vehicle} from '../../../constants/propTypesDefinitions';
import {createFetchVehicleRemove} from '../../../backend/apiCalls';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Button} from 'antd';
import {Trans, t} from '@lingui/macro';

/**
 * @dusan
 */

class VehicleRemove extends React.PureComponent {
    static propTypes = {
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        vehicle: Vehicle.isRequired,
    };

    render() {
        const {vehicle = {}} = this.props;
        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        return <FormModal
            openNode={
                <Tooltip title={<Trans>Odstránenie vozidla</Trans>}>
                    <Button className="mx-1" size="small" type="danger" icon="close"/>
                </Tooltip>
            }
            values={{}}
            onFinishSuccessful={() => {reloadData([GLOBAL_DATA.VEHICLES])}}
            title={<span>
                    <Trans>Odstránenie vozidla</Trans>
                    <span className="ml-2">{vehicle.name}</span>
                </span>}
            Form={generalNoParameterFormPC(
                <Trans>Naozaj chcete odstrániť vozidlo?</Trans>,
                createFetchVehicleRemove(vehicle.id),
            )}
            Response={null}
            Failed={generalFailedPC(t`Nepodarilo sa odstrániť vozidlo.`)}
        />;
    }

}

export default withDataHOC([GLOBAL_DATA.RELOAD_DATA])(VehicleRemove);