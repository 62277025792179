import React from 'react';
import List from '../../general/List';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Vehicles} from '../../../constants/propTypesDefinitions';
import VehicleEdit from './VehicleEdit';
import {Trans, t} from '@lingui/macro';
import VehicleRemove from './VehicleRemove';
import VisibilityIcon from '../../general/VisibilityIcon';

/**
 * @dusan
 */

class VehiclesList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
        }
    }

    static propTypes = {
        [GLOBAL_DATA.VEHICLES]: Vehicles.isRequired,
    };

    onSelect = (vehicleId) => {
        this.setState({
            selectedId: vehicleId,
        })
    };

    render() {
        const {selectedId} = this.state;
        const vehicles = this.props[GLOBAL_DATA.VEHICLES];
        return <List
                values={vehicles}
                onItemClick={(value) => {
                    this.onSelect(value.id);
                }}
                selectedValueId={selectedId}
            >
                {vehicles.map((obj = {}, index) => {
                    return <div key={index} className="d-flex flex-wrap align-items-center">
                        <VisibilityIcon isVisible={obj.is_active}/>
                        <span className="mr-2 font-weight-bold">{obj.name}</span>
                        {'|'}
                        <span className="mx-2">{obj.description}</span>
                        {'|'}
                        <span className="mx-2">
                            <Trans>Zodp. osoba:</Trans>
                            <span className="ml-2">{obj.user}</span>
                        </span>
                        {'|'}
                        <span className="mx-2">
                            <Trans>Uzatvorené do:</Trans>
                            <span className="ml-2">{obj.closed_until}</span>
                        </span>
                        {obj.id == selectedId ? [
                            <VehicleEdit
                                key="edit"
                                vehicle={obj}
                            />,
                            < VehicleRemove
                                key="remove"
                                vehicle={obj}
                            />
                        ] : null}
                    </div>
                })}
        </List>;
    }

}

export default withDataHOC([GLOBAL_DATA.VEHICLES])(VehiclesList);