import React from 'react';
import Helmet from '../project/Helmet';
import VehicleAdd from './vehicles/VehicleAdd';
import VehicleList from './vehicles/VehicleList';
import { t } from '@lingui/macro';

/**
 * @dusan
 */

class VehiclesPage extends React.PureComponent {
    render() {
        return <React.Fragment>
            <Helmet
                title={t`Vozidlá`}
            />
            <div className="full-size-height d-flex flex-column">
                <div className="pt-2 add-max-width flex-item-static">
                    <VehicleAdd/>
                </div>
                <div className="m-3 overflow-auto flex-item-dynamic-1">
                    <VehicleList/>
                </div>
            </div>
        </React.Fragment>;
    }

}

export default VehiclesPage;